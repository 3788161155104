<template>
  <mdb-container style="padding: 0">
    <mdb-row class="justify-content-md-center mb-2">
      <mdb-col class="justify-content-md-center">
        <img
          :src="
            redirect == 1
              ? content.recommender_image_redirect
              : content.main_image
          "
          class="img-fluid z-depth-1"
        />
      </mdb-col>
    </mdb-row>
    <mdb-container>
      <mdb-row class="justify-content-md-center" v-if="!imageOnly">
        <mdb-col class="justify-content-md-center">
          <h4
            v-html="
              name === undefined
                ? redirect == 1
                  ? content.recommender_title1_redirect
                  : content.main_title1
                : 'Hei ' + name + '<br><br>' + content.main_title1
            "
          ></h4>
        </mdb-col>
      </mdb-row>
      <mdb-row class="justify-content-md-center" v-if="!imageOnly">
        <mdb-col class="justify-content-md-center">
          <h6
            v-html="
              redirect
                ? content.recommender_title2_redirect
                : content.main_title2
            "
          ></h6>
        </mdb-col>
      </mdb-row>
      <mdb-row class="justify-content-md-center" v-if="!imageOnly">
        <mdb-col class="justify-content-md-center">
          <p style="font-size: 14px">
            {{
              direct ? content.recommender_title3_redirect : content.main_title3
            }}
          </p>
        </mdb-col>
      </mdb-row>
    </mdb-container>
  </mdb-container>
</template>
<script>
import { mdbRow, mdbCol, mdbContainer } from "mdbvue";
export default {
  name: "MainHeader",
  components: {
    mdbRow,
    mdbCol,
    mdbContainer,
  },
  props: {
    content: Object,
    redirect: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: undefined,
    },
    imageOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
<style lang=""></style>
