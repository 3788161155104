<template>
  <mdb-container>
    <mdb-row class="">
      <mdb-col class="text-nowrap">
        <h6>
          {{ $t("email.sender") }}<span>{{ txObject.sender }}</span>
        </h6>
      </mdb-col>
    </mdb-row>
    <mdb-row class="">
      <mdb-col class="">
        <mdb-input
          v-model="txObject.receivers"
          type="textarea"
          :rows="4"
          size="lg"
          outline
          inputClass="z-depth-1 p-3"
          @change="checkEmails"
          :label="$t('email.receiver_hint')"
        />
      </mdb-col>
    </mdb-row>
    <SubjectBodyTemplate
      :rows="20"
      :subjectBody="emailContent"
      v-on:subjectBodyChanged="subjectBodyChanged"
    />
    <mdb-row class="">
      <mdb-col class="">
        <mdb-btn
          :disabled="validEmailNotExists"
          class="mb-5"
          size="block"
          :style="color"
          @click="recommendEmail()"
          >{{ $t("email.send_recommendation") }}</mdb-btn
        >
      </mdb-col>
    </mdb-row>
  </mdb-container>
</template>

<script>
import { mdbContainer, mdbRow, mdbCol, mdbInput, mdbBtn } from "mdbvue";
import SubjectBodyTemplate from "@/components/SubjectBodyTemplate";
import helper from "@/mixins/helper";
import api from "@/mixins/api";
export default {
  name: "EmailInput",
  mixins: [helper, api],
  components: {
    mdbBtn,
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbInput,
    SubjectBodyTemplate,
  },
  props: { content: Object },
  data() {
    return {
      me: null,
      txObject: {
        type: "email",
        transactionID: null,
        surrogate: this.$store.getters.getRecommenderSurrogate,
        sender: this.$store.getters.getRecommenderEmail,
        subject: this.content.recommender_subject,
        text: this.content.recommender_subject,
        link: "",
        linktext: this.content.recommended_linktext,
        image: this.content.recommended_email_image,
        vendor: this.$store.getters.getVendorId,
        receivers: "",
      },
      validEmailNotExists: true,
      emailContent: {
        subject: this.content.recommender_subject,
        body: this.content.recommender_body,
      },
      color: { "background-color": this.content.color },
      borderColor: { "border-color": this.content.color },
      link: "https://recommend.virabeli.fi/recommended/",
    };
  },
  methods: {
    subjectBodyChanged(val) {
      this.emailContent = val;
    },
    checkEmails() {
      this.txObject.receivers = this.txObject.receivers.replace(/\s+/g, "");
      this.validEmailNotExists = false;
      this.txObject.receivers.split(",").forEach((element) => {
        if (!this.validateEmail(element)) {
          this.validEmailNotExists = true;
          return;
        }
      });
    },
    async recommendEmail() {
      this.txObject.transactionID = this.generateUUID();
      this.createFirebaseLink(this.link, this.txObject.transactionID).then(
        (res) => this.sendTransaction(res.shortLink)
      );
    },
    async sendTransaction(shortLink) {
      this.txObject.link = shortLink;
      this.txObject.subject = this.emailContent.subject;
      this.txObject.text = this.emailContent.body;
      console.log(this.txObject);
      this.createTransaction(this.txObject).then((res) => {
        if (res === undefined)
          this.$notify.error({
            message: this.$t("email.email_sent_not_success"),
            position: "top right",
            timeOut: this.$store.state.notifytimeoutshort,
          });
        else {
          this.$notify.success({
            message: this.$t("email.email_sent_success"),
            position: "top right",
            timeOut: this.$store.state.notifytimeoutshort,
          });
          this.$router.push({
            path: "/recommend/" + this.txObject.vendor,
            query: {
              email: this.$store.getters.getRecommenderEmail,
              name: this.$store.getters.getRecommenderName,
              redirect: 1,
            },
          });
        }
      });
    },
  },
  computed: {},
};
</script>

<style></style>
