<template>
  <div>
    <mdb-row class="" v-if="subjectBody.subject.length > 0">
      <mdb-col class="">
        <mdb-input
          type="textarea"
          size="lg"
          outline
          inputClass="z-depth-1 p-3"
          v-model="localContent.subject"
          @change="localSubjectChanged"
          :label="$t('email.subject_hint')"
        />
      </mdb-col>
    </mdb-row>
    <mdb-row class="">
      <mdb-col class="" v-if="rows !== undefined">
        <mdb-input
          type="textarea"
          ref="textarea"
          size="lg"
          :rows="rows"
          outline
          inputClass="z-depth-1 p-3"
          v-model="localContent.body"
          @change="localBodyChanged"
          :label="$t('email.body_hint')"
        />
      </mdb-col>
    </mdb-row>
  </div>
</template>

<script>
import { mdbRow, mdbCol, mdbInput } from "mdbvue";
export default {
  name: "SubjectBodyTemplate",
  components: {
    mdbRow,
    mdbCol,
    mdbInput,
  },
  props: {
    subjectBody: Object,
    rows: Number,
  },
  data() {
    return {
      localContent: this.subjectBody,
    };
  },
  methods: {
    localSubjectChanged(val) {
      this.localContent.subject = val;
      this.$emit("subjectBodyChanged", this.localContent);
    },
    localBodyChanged(val) {
      this.localContent.body = val;
      this.$emit("subjectBodyChanged", this.localContent);
    },
  },
  computed: {},
  watch: {},
};
</script>

<style></style>
