import { v4 as uuidv4 } from "uuid";
import usersData from "@/assets/vendorsData.json";

export default {
  methods: {
    validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    getLastItem(items) {
      var output = items.slice(-1)[0];
      if (output.length == 0) return items[0];
      return output;
    },
    generateUUID() {
      return uuidv4();
    },
    getVendorData(vnd) {
      var vendors = usersData.vendors;
      return vendors.find((vendor) => vendor.id === vnd);
    },
    getUrlParam(param) {
      try {
        return this.$route.params[param];
      } catch (error) {
        console.log(error);
        return "";
      }
    },
    getUrlQuery(query) {
      try {
        return this.$route.query[query];
      } catch (error) {
        return null;
      }
    },
  },
};
