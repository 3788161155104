import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbvue/lib/css/mdb.min.css";
import VueGtag from "vue-gtag";
import Vue from "vue";
import App from "./App";
import router from "./router";
import store from "./store";

import i18n from "./lang/lang";

import axios from "axios";
import VueAxios from "vue-axios";

import Vuex from "vuex";
Vue.use(Vuex);

Vue.use(VueGtag, {
  config: { id: "G-1SGGWR4LWB" },
});

Vue.config.productionTip = false;
Vue.config.devtools = true;
import Notify from "mdbvue/lib/components/Notify";
Vue.use(Notify);

axios.defaults.withCredentials = false;
Vue.use(VueAxios, axios);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
