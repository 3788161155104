<template>
  <mdb-container class="recommend" size="sm">
    <MainHeader :content="vendorData" :image-only="true" v-if="loaded" />
    <EmailInput :content="vendorData" v-if="loaded" />
  </mdb-container>
</template>

<script>
import MainHeader from "@/components/MainHeader";
import EmailInput from "@/components/EmailInput.vue";
import { mdbContainer } from "mdbvue";

export default {
  name: "EmailView",
  components: {
    MainHeader,
    EmailInput,
    mdbContainer,
  },
  data() {
    return {
      vendorData: this.$store.getters.getCurrentVendor,
      loaded: false,
      color: {},
    };
  },
  mounted() {
    this.color = { "background-color": this.vendorData.color };
    this.loaded = true;
  },
  methods: {},
  computed: {},
};
</script>

<style></style>
