var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('mdb-container',[_c('SubjectBodyTemplate',{attrs:{"subjectBody":_vm.whatsAppContent,"rows":8},on:{"subjectBodyChanged":_vm.subjectBodyChanged}}),_c('mdb-row',[_c('mdb-col',{attrs:{"md":"8"}},[_c('MazPhoneNumberInput',{attrs:{"noCountrySelector":"","noExample":"","default-country-code":"FI","size":"lg","translations":{
          countrySelectorLabel: this.$t('recommend.countryselectorlabel'),
          countrySelectorError: this.$t('recommend.countryselectorerror'),
          countrySelectorSearchPlaceholder: this.$t(
            'recommend.countrysearch'
          ),
          phoneNumberLabel: this.$t('recommend.phonenumberlabel'),
          example: this.$t('recommend.phonenumberexample'),
        },"preferred-countries":['FI', 'SE', 'NO', 'DK']},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}})],1),_c('mdb-col',{attrs:{"md":"4"}},[_c('mdb-btn',{staticClass:"mb-5",style:(_vm.color),attrs:{"block":""},on:{"click":_vm.recommendWA}},[_vm._v(_vm._s(_vm.$t("recommend.sendwhatsapp")))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }