<template>
  <mdb-container class="recommend" size="sm">
    <MainHeader :content="vendorData" />
  </mdb-container>
</template>

<script>
import { mdbContainer } from "mdbvue";
import MainHeader from "@/components/MainHeader";
export default {
  name: "ThankYouView",
  components: {
    mdbContainer,
    MainHeader,
  },
  data() {
    return {
      vendorData: {
        main_image: "",
        main_title1: this.$store.getters.getThankYouText,
      },
    };
  },
};
</script>

<style></style>
