<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
@import url("https://use.typekit.net/ygq5wau.css");
/*@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");*/

#app {
  text-align: center;
  margin-top: 150px;
  font-family: myriad-pro, sans-serif;
}
p {
  font-size: 12px;
}
body {
  font-family: myriad-pro, sans-serif;
  height: 100%;
}
html {
  height: 100%;
}
.cs-pink {
  background-color: #d5007e !important;
  color: white !important;
}
.md-form.md-outline label {
  font-size: 0.75rem;
}
.bg {
  background-color: white;
  background-image: url("~@/assets/img/bg.jpg");
  height: 100%;
  background-position: center;
  background-repeat: repeat-y;
  background-size: cover;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 720px;
  }
}
</style>
