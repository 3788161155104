<template>
  <mdb-container style="padding: 0" size="sm">
    <MainHeader :content="contentData" v-if="contentLoaded" />
    <mdb-container>
      <mdb-row class="justify-content-md-center">
        <mdb-col>
          <component
            :vendorData="vendorData"
            :transactionData="transactionData"
            v-bind:is="currentComponent"
            v-if="contentLoaded"
          ></component>
        </mdb-col>
      </mdb-row>
    </mdb-container>
  </mdb-container>
</template>
<script>
import MainHeader from "@/components/MainHeader";
import { mdbContainer, mdbRow, mdbCol } from "mdbvue";
import helper from "@/mixins/helper";
import api from "@/mixins/api";
export default {
  name: "Vendors",
  mixins: [helper, api],
  components: {
    "city-shoppari": () => import("@/vendors/CityShoppariRecommended"),
    "koti-pizza": () => import("@/vendors/KotipizzaRecommended"),
    "valitut-palat": () => import("@/vendors/ValitutPalatRecommended"),
    "terveydenhoitaja-liitto": () =>
      import("@/vendors/TerveydenhoitajaliittoRecommended"),
    mdbContainer,
    mdbRow,
    mdbCol,
    MainHeader,
  },
  props: {
    transactionId: String,
  },
  data() {
    return {
      transactionData: null,
      vendorData: null,
      contentData: new Object(),
      currentComponent: null,
      contentLoaded: false,
    };
  },
  methods: {
    initilizeVendor() {
      this.getVendor();
      this.vendorLoader();
      this.setContent();
      this.contentLoaded = true;
    },
    setContent() {
      this.contentData.main_image = this.vendorData.recommended_main_image;
      this.contentData.main_title1 = this.vendorData.recommended_main_title1;
      this.contentData.main_title2 = this.vendorData.recommended_main_title2;
      this.contentData.main_title3 = this.vendorData.recommended_main_title3;
    },
    async getTxData() {
      this.getTransactionData(this.transactionId).then((res) => {
        if (res !== undefined) {
          this.transactionData = res[0];
          this.initilizeVendor();
        }
      });
    },
    getVendor() {
      this.vendorData = this.getVendorData(this.transactionData.vendorId);
    },
    vendorLoader() {
      this.currentComponent = this.vendorData.componentname;
    },
  },
  mounted() {
    this.getTxData();
  },
};
</script>
<style lang=""></style>
