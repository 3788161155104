var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('mdb-container',{staticStyle:{"padding":"0"}},[_c('mdb-row',{staticClass:"justify-content-md-center mb-2"},[_c('mdb-col',{staticClass:"justify-content-md-center"},[_c('img',{staticClass:"img-fluid z-depth-1",attrs:{"src":_vm.redirect == 1
            ? _vm.content.recommender_image_redirect
            : _vm.content.main_image}})])],1),_c('mdb-container',[(!_vm.imageOnly)?_c('mdb-row',{staticClass:"justify-content-md-center"},[_c('mdb-col',{staticClass:"justify-content-md-center"},[_c('h4',{domProps:{"innerHTML":_vm._s(
            _vm.name === undefined
              ? _vm.redirect == 1
                ? _vm.content.recommender_title1_redirect
                : _vm.content.main_title1
              : 'Hei ' + _vm.name + '<br><br>' + _vm.content.main_title1
          )}})])],1):_vm._e(),(!_vm.imageOnly)?_c('mdb-row',{staticClass:"justify-content-md-center"},[_c('mdb-col',{staticClass:"justify-content-md-center"},[_c('h6',{domProps:{"innerHTML":_vm._s(
            _vm.redirect
              ? _vm.content.recommender_title2_redirect
              : _vm.content.main_title2
          )}})])],1):_vm._e(),(!_vm.imageOnly)?_c('mdb-row',{staticClass:"justify-content-md-center"},[_c('mdb-col',{staticClass:"justify-content-md-center"},[_c('p',{staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.direct ? _vm.content.recommender_title3_redirect : _vm.content.main_title3)+" ")])])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }