<template>
  <mdb-container class="recommend" size="sm">
    <MainHeader :content="vendorData" :image-only="true" v-if="loaded" />
    <WhatsAppInput :content="vendorData" v-if="loaded" />
  </mdb-container>
</template>

<script>
import MainHeader from "@/components/MainHeader";
import WhatsAppInput from "@/components/WhatsAppInput.vue";
import { mdbContainer } from "mdbvue";
export default {
  name: "WhatsappView",
  components: {
    mdbContainer,
    WhatsAppInput,
    MainHeader,
  },
  data() {
    return {
      vendorData: this.$store.getters.getCurrentVendor,
      loaded: false,
    };
  },
  mounted() {
    this.loaded = true;
  },
};
</script>

<style></style>
