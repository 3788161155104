<template>
  <mdb-container>
    <SubjectBodyTemplate
      :subjectBody="whatsAppContent"
      :rows="8"
      v-on:subjectBodyChanged="subjectBodyChanged"
    />
    <mdb-row>
      <mdb-col md="8">
        <MazPhoneNumberInput
          noCountrySelector
          noExample
          v-model="phoneNumber"
          default-country-code="FI"
          size="lg"
          :translations="{
            countrySelectorLabel: this.$t('recommend.countryselectorlabel'),
            countrySelectorError: this.$t('recommend.countryselectorerror'),
            countrySelectorSearchPlaceholder: this.$t(
              'recommend.countrysearch'
            ),
            phoneNumberLabel: this.$t('recommend.phonenumberlabel'),
            example: this.$t('recommend.phonenumberexample'),
          }"
          :preferred-countries="['FI', 'SE', 'NO', 'DK']"
        />
      </mdb-col>
      <!--mdb-col md="8">
        <mdb-input
          v-model="phoneNumber"
          type="text"
          :label="$t('recommend.phonenumberlabel')"
          bg
          size="sm"
          :small="$t('recommend.phonenumberexample')"
        />
      </mdb-col-->
      <mdb-col md="4">
        <mdb-btn block class="mb-5" :style="color" @click="recommendWA">{{
          $t("recommend.sendwhatsapp")
        }}</mdb-btn>
      </mdb-col>
    </mdb-row>
  </mdb-container>
</template>

<script>
import { MazPhoneNumberInput } from "maz-ui";
import { mdbBtn, mdbContainer, mdbRow, mdbCol } from "mdbvue";
import SubjectBodyTemplate from "@/components/SubjectBodyTemplate";
import helper from "@/mixins/helper";
import api from "@/mixins/api";
export default {
  name: "WhatsAppInput",
  mixins: [helper, api],
  components: {
    SubjectBodyTemplate,
    mdbBtn,
    mdbContainer,
    mdbRow,
    mdbCol,
    MazPhoneNumberInput,
  },
  props: { content: Object },
  data() {
    return {
      txObject: {
        type: "whatsapp",
        transactionID: null,
        surrogate: this.$store.getters.getRecommenderSurrogate,
        sender: this.$store.getters.getRecommenderEmail,
        subject: "",
        text: "",
        link: "",
        linktext: this.content.recommended_linktext,
        image: this.content.main_image,
        vendor: this.$store.getters.getVendorId,
        receivers: "",
      },
      link: "https://recommend.virabeli.fi/recommended/",
      senderMessage: this.content.recommender_message,
      templateMessage: this.content.recommender_template_message,
      whatsappapi: "https://api.whatsapp.com/send?phone=",
      firebasedl: "https://csvira.page.link",
      firebaseapi:
        "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBuszHXtrgN2QUD-18y4Dyuu9f2JbxiiNk",
      phoneNumber: null,
      whatsAppContent: {
        subject: "",
        body: this.content.recommender_whatsapp_body,
      },
      color: { "background-color": this.content.color },
    };
  },
  methods: {
    subjectBodyChanged(val) {
      this.whatsAppContent = val;
    },
    async recommendWA() {
      this.txObject.transactionID = this.generateUUID();
      this.createFirebaseLink(this.link, this.txObject.transactionID).then(
        (res) => this.sendTransaction(res.shortLink)
      );
    },
    async sendTransaction(shortLink) {
      this.txObject.link = shortLink;
      this.txObject.text = this.whatsAppContent.body;
      this.createTransaction(this.txObject).then((res) => {
        if (res === undefined) {
          this.$notify.error({
            message: this.$t("email_sent_not_success"),
            position: "top right",
            timeOut: this.$store.state.notifytimeoutshort,
          });
        } else {
          this.$notify.success({
            message: this.$t("email_sent_success"),
            position: "top right",
            timeOut: this.$store.state.notifytimeoutshort,
          });
          this.useWhatsappApi();
        }
      });
    },
    useWhatsappApi() {
      window.open(
        this.whatsappapi +
          encodeURI(
            this.phoneNumber +
              "&text=" +
              this.txObject.text +
              "\n\n" +
              this.txObject.link
          ) //, "_blank"
      );
    },
  },
  computed: {},
};
</script>

<style></style>
