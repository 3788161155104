import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    recommenderEmail: "",
    recommenderName: "",
    recommenderSurrogate: "",
    phoneNumber: "",
    vendorId: "",
    currentVendor: [],
    thankYouText: "",
  },
  mutations: {
    updateCurrentVendor(state, currentVendor) {
      state.currentVendor = currentVendor;
    },
    updateRecommenderEmail(state, recommenderEmail) {
      state.recommenderEmail = recommenderEmail;
    },
    updateRecommenderName(state, recommenderName) {
      state.recommenderName = recommenderName;
    },
    updateRecommenderSurrogate(state, recommenderSurrogate) {
      state.recommenderSurrogate = recommenderSurrogate;
    },
    updatePhoneNumber(state, phoneNumber) {
      state.phoneNumber = phoneNumber;
    },
    updateVendorId(state, vendorId) {
      state.vendorId = vendorId;
    },
    updateThankYouText(state, thankYouText) {
      state.thankYouText = thankYouText;
    },
  },
  getters: {
    getThankYouText: (state) => {
      return state.thankYouText;
    },
    getCurrentVendor: (state) => {
      return state.currentVendor;
    },
    getRecommenderEmail: (state) => {
      return state.recommenderEmail;
    },
    getRecommenderSurrogate: (state) => {
      return state.recommenderSurrogate;
    },
    getPhoneNumber: (state) => {
      return state.phoneNumber;
    },
    getVendorId: (state) => {
      return state.vendorId;
    },
  },
  actions: {},
});
