<template>
  <mdb-container style="padding: 0" size="sm">
    <Vendors v-if="transactionLoaded" :transactionId="transactionId" />
  </mdb-container>
</template>

<script>
import { mdbContainer } from "mdbvue";
import Vendors from "@/vendors/Vendors";
import helper from "@/mixins/helper";
export default {
  name: "RecommendedView",
  components: {
    Vendors,
    mdbContainer,
  },
  mixins: [helper],
  data() {
    return {
      transactionId: "",
      transactionLoaded: false,
    };
  },
  methods: {},
  mounted() {
    this.transactionId = this.getUrlParam("transactionId");
    this.transactionLoaded = true;
  },
  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  font-weight: normal;
  padding-top: 20px;
  padding-bottom: 30px;
}
p {
  color: #969696;
  margin-bottom: 0;
  font-size: 14px;
}
.recommended {
  background-color: #6f7782 !important;
}
</style>
