<template>
  <mdb-container size="sm">
    <MainHeader :content="vendorData" v-if="loaded" />
  </mdb-container>
</template>

<script>
import { mdbContainer } from "mdbvue";
import MainHeader from "@/components/MainHeader";
import helper from "@/mixins/helper";
export default {
  name: "RecommendView",
  components: {
    mdbContainer,
    MainHeader,
  },
  mixins: [helper],
  data() {
    return {
      loaded: false,
      vendorData: null,
      contentData: null,
    };
  },
  methods: {
    getDefaultVendorData() {
      this.vendorData = this.getVendorData("default");
      this.loaded = true;
    },
  },
  mounted() {
    this.getDefaultVendorData();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
