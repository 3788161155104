const firebasedl = "https://csvira.page.link";
const BASEURL = "https://recommend.virabeli.fi";
//const BASEURLD = "http://localhost:5000";
//const AWSBASEURL = "https://suosittelekaverillesi.virabeli.fi";
const createtx = "/api/1.0/createtx";
const closetx = "/api/1.0/closetx";
const clicktx = "/api/1.0/clicktx";
const gettx = "/api/1.0/transaction";
const firebaseapi =
  "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBuszHXtrgN2QUD-18y4Dyuu9f2JbxiiNk";

export default {
  methods: {
    getHost() {
      if (window.location.hostname.includes("localhost")) return BASEURL;
      else if (window.location.hostname.includes("recommend")) return BASEURL;
      else return BASEURL;
    },
    createFirebaseLink(link, uuid) {
      const body = {
        dynamicLinkInfo: {
          domainUriPrefix: firebasedl,
          link: link + uuid,
        },
      };
      return this.$http
        .post(firebaseapi, body)
        .then((res) => res.data)
        .catch((err) => console.error(err));
    },
    createTransaction(body) {
      return this.$http
        .post(this.getHost() + createtx, body)
        .then((res) => res.data)
        .catch((err) => console.error(err));
    },
    closeTransaction(body) {
      return this.$http
        .post(this.getHost() + closetx, body)
        .then((res) => {
          return res.status;
        })
        .catch((err) => {
          return err.response.status;
        });
    },
    clickTransaction(body) {
      return this.$http
        .post(this.getHost() + clicktx, body)
        .then((res) => {
          return res.status;
        })
        .catch((err) => {
          return err.response.status;
        });
    },
    getTransactionData(transactionId) {
      return this.$http
        .get(this.getHost() + gettx + "/" + transactionId)
        .then((res) => res.data)
        .catch((err) => console.error(err));
    },
  },
};
