<template>
  <mdb-container style="padding: 0" size="sm">
    <MainHeader
      :redirect="redirect"
      :content="vendorData"
      :name="name"
      v-if="loaded"
    />
    <mdb-container v-if="loaded">
      <mdb-row>
        <mdb-col col="8">
          <mdb-btn-group vertical>
            <mdb-btn
              v-if="vendorData.method_email"
              class="text-left pl-1"
              fas
              icon="at"
              iconClass="fa-sm"
              size="sm"
              :style="color"
              @click="recommendEmail()"
              >{{ $t("recommend.email") }}</mdb-btn
            >
            <mdb-btn
              v-if="vendorData.method_mese"
              class="text-left pl-1"
              fab
              icon="facebook-messenger"
              iconClass="fa-sm"
              size="sm"
              :style="color"
              >{{ $t("recommend.messenger") }}</mdb-btn
            >
            <mdb-btn
              v-if="vendorData.method_wa"
              class="color text-left pl-1"
              fab
              icon="whatsapp"
              iconClass="fa-sm"
              size="sm"
              :style="color"
              @click="recommendWA()"
              >{{ $t("recommend.whatsapp") }}</mdb-btn
            >
            <mdb-btn
              v-if="vendorData.method_postcard"
              class="grey darken-1 text-left pl-1"
              fas
              icon="envelope-open-text"
              iconClass="fa-sm"
              size="sm"
              :style="color"
              >{{ $t("recommend.postcard") }}</mdb-btn
            >
          </mdb-btn-group>
        </mdb-col>
      </mdb-row>
    </mdb-container>
  </mdb-container>
</template>

<script>
import { mdbContainer, mdbRow, mdbCol, mdbBtnGroup, mdbBtn } from "mdbvue";
import MainHeader from "@/components/MainHeader";
import helper from "@/mixins/helper";
export default {
  name: "RecommendView",
  mixins: [helper],
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbBtnGroup,
    mdbBtn,
    MainHeader,
  },
  data() {
    return {
      email: this.getUrlQuery("email"),
      name: this.getUrlQuery("name"),
      surrogate: this.getUrlQuery("surrogate"),
      redirect: parseInt(this.getUrlQuery("redirect")),
      vendorId: this.getUrlParam("vendorId"),
      vendorData: "",
      color: {},
      loaded: false,
    };
  },
  methods: {
    getVendor() {
      this.vendorData = this.getVendorData(this.vendorId);
      this.color = { "background-color": this.vendorData.color };
      this.$store.commit("updateCurrentVendor", this.vendorData);
      this.loaded = true;
    },
    recommendEmail() {
      this.$store.commit("updateRecommenderEmail", this.email);
      this.$store.commit("updateRecommenderName", this.name);
      this.$store.commit("updateRecommenderSurrogate", this.surrogate);
      this.$store.commit("updateVendorId", this.vendorId);
      this.$router.push({
        path: "/recommendemail",
      });
    },
    recommendWA() {
      this.$store.commit("updateRecommenderEmail", this.email);
      this.$store.commit("updateRecommenderSurrogate", this.surrogate);
      this.$store.commit("updateVendorId", this.vendorId);
      this.$router.push({
        path: "/recommendwa",
      });
    },
  },
  mounted() {
    this.getVendor();
    console.log(this.vendorData);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
