import Vue from "vue";
import Router from "vue-router";
import RecommendedView from "@/views/RecommendedView";
import RecommendView from "@/views/RecommendView";
import RecommendMainView from "@/views/RecommendMainView";
import EmailView from "@/views/EmailView";
import WhatsAppView from "@/views/WhatsappView";
import ThankYouView from "@/views/ThankYouView";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "RecommendMain",
      component: RecommendMainView,
    },
    {
      path: "/recommended/:transactionId",
      name: "Recommended",
      component: RecommendedView,
    },
    {
      path: "/recommend",
      name: "Recommend",
      component: RecommendMainView,
    },
    {
      path: "/recommend/:vendorId",
      name: "Recommend",
      component: RecommendView,
    },
    {
      path: "/recommendemail",
      name: "RecommendEmail",
      component: EmailView,
    },
    {
      path: "/recommendwa",
      name: "RecommendWhatsApp",
      component: WhatsAppView,
    },
    {
      path: "/thankyou",
      name: "ThankYou",
      component: ThankYouView,
    },
  ],
});
